import WebServiceRequest from '../Api/WebServiceRequest'

class BlogCategoryDeleteRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
  }

  setId(id) {
    super.setTag('BlogCategory/DeleteBlogCategory?BlogCategoryId=' + id)
  }
}

class BlogCategoryGetAllRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('BlogCategory/GetAll')
  }
}

class BlogCategoryGetRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
  }

  setId(id) {
    super.setTag('BlogCategory/GetById?blogCategoryId=' + id)
  }
}

class BlogCategoryUpdateRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setTag('BlogCategory/UpdateBlogCategory')
  }

  setParams(data) {
    super.setRequestParamDataObj(data)
  }
}

class BlogCommentDeleteRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
  }

  setId(id) {
    super.setTag('BlogComment/DeleteBlogComment?BlogCommentId=' + id)
  }
}

class BlogCommentGetAllByBlogIdRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
  }

  setId(id) {
    super.setTag('BlogComment/GetAllByBlogId?blogId=' + id)
  }
}

class BlogCommentGetAllRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setTag('BlogComment/GetAll')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class BlogCommentGetByIdRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
  }

  setId(id) {
    super.setTag('BlogComment/GetById?blogCommentId=' + id)
  }
}

class BlogCommentUpdateRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setTag('BlogComment/UpdateBlogComment')
  }

  setParams(data) {
    super.setRequestParamDataObj(data)
  }
}

class BlogCreateBlogRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('POST')
    super.setTag('Blogs')
  }

  setParams(data) {
    super.setFormData(data)
  }
}

class BlogDeleteRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('DELETE')
    super.setTag('Blogs/DeleteBlog')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class BlogGetByIdRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('Blogs/GetBy')

  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class BlogUpdateBlogRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('PUT')
    super.setTag('Blogs/UpdateBlog')
  }

  setParams(data) {
    super.setFormData(data)
  }

  setQuery(data) {
    super.setRequestParam(data)
  }
}

class CreateBlogCommentRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setTag('BlogComment/CreateBlogComment')
  }

  setBlogId(blogId) {
    super.setRequestParam('blogId', blogId)
  }

  setMessage(message) {
    super.setRequestParam('message', message)
  }

  setParentId(parentId) {
    super.setRequestParam('parentId', parentId)
  }

}

class BlogCategoryCreateRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setTag('BlogCategory/CreateBlogCategory')
  }

  setParams(data) {
    super.setRequestParamDataObj(data)
  }
}

class BlogGetAllRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setTag('Blog/GetAll')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class BlogGetAllForAdminRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('Blogs/GetAll')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class GetBlogContentBy extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('Blogs/GetBlogContentBy')
  }

  setParams(data) {
    super.setRequestParam(data)
  }

}

class CreateBlogContent extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('POST')
    super.setTag('Blogs/CreateBlogContent')
  }

  setParams(data) {
    super.setRequestParam(data)
  }

  setPayload(data) {
    super.setRequestParamDataObj(data)
  }

}

class DeleteBlogContent extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('DELETE')
    super.setTag('Blogs/DeleteBlogContent')
  }

  setParams(data) {
    super.setRequestParam(data)
  }

}
class UpdateBlogContent extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('PUT')
    super.setTag('Blogs/UpdateBlogContent')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
  setPayload(data) {
    super.setRequestParamDataObj(data)
  }
}
class ChangeBlogStatus extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('POST')
    super.setTag('Blogs/ChangeBlogStatus')
  }

  setParams(data) {
    super.setRequestParamDataObj(data)
  }

}
export {
  BlogCategoryDeleteRequest,
  BlogCategoryGetAllRequest,
  BlogCategoryGetRequest,
  BlogCategoryUpdateRequest,
  BlogCommentDeleteRequest,
  BlogCommentGetAllRequest,
  CreateBlogContent,
  BlogCommentGetByIdRequest,
  BlogCommentUpdateRequest,
  BlogCreateBlogRequest,
  BlogDeleteRequest,
  BlogGetAllRequest,
  BlogGetByIdRequest,
  BlogUpdateBlogRequest,
  CreateBlogCommentRequest,
  ChangeBlogStatus,
  BlogCategoryCreateRequest,
  BlogGetAllForAdminRequest,
  GetBlogContentBy,
  DeleteBlogContent,
    UpdateBlogContent
}
