import WebServiceRequest from '../Api/WebServiceRequest'

class GetAllGames extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('Game')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class GetGameServicesByGameId extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('Game/GetGameServiceByGameId')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class GetNestedGames extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('Game/GetGeneric')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class GetGameDetail extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
  }

  setParams(data) {
    super.setTag(`Game/GetBy/${data.id}`)
  }
}

class CreateGame extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setTag(`Game`)

  }

  setParams(data) {
    super.setFormData(data)
  }
}

class UpdateGame extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('PUT')

  }

  setPartialUrl(data) {
    super.setTag(`Game/UpdateGame/${data.id}`)
  }

  setParams(data) {
    super.setFormData(data)
  }
}

class DeleteGame extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('DELETE')
    super.setTag('Game/DeleteGame')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class GetGameServiceCategoriesByGameId extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
  }

  setPartialUrl(data) {
    super.setTag(`Game/GetGameServiceCategoryByGameId/${data.id}`)
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class RemoveGamePlatform extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('DELETE')
    super.setTag(`Platform/DeleteGamePlatform`)
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

export {
  GetAllGames,
  DeleteGame,
  GetGameDetail,
  CreateGame,
  GetGameServiceCategoriesByGameId,
  UpdateGame,
  GetNestedGames,
  GetGameServicesByGameId,
  RemoveGamePlatform
}
