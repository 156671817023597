<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <div v-if="blog">
      <b-modal
          id="modal-delete"
          cancelTitle="Cancel"
          title="Delete Blog Content"
          centered
          ok-title="Delete"
          @ok="deleteBlogContent"
      >
        <span>Are you sure?</span>
      </b-modal>
      <validation-observer
          ref="refFormObserver"
      >
        <!-- form -->
        <b-card>
          <b-form
              class="mt-2"
          >
            <b-row>
              <b-col md="4">
                <b-form-group
                    class="mb-2 mt-25"
                    label="Title"
                >
                  <validation-provider
                      #default="{ errors }"
                      rules="required"
                  >
                    <b-form-input
                        id="blog-edit-title"
                        v-model="blog.title"
                        :state="errors.length > 0 ? false:null"
                    />
                    <!--                  <small class="text-danger mt-3">{{ errors[0] }}</small>-->
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    class="mb-2"
                    label="Game"
                >
                  <validation-provider
                      #default="{ errors }"
                      rules="required"
                  >
                  <v-select
                      v-if="games"
                      v-model="blog.gameId"
                      :options="games"
                      :class="{'border-danger rounded':errors.length > 0 }"
                      :reduce="name => name.id"
                      label="name"
                  />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    class="mb-2"
                    label="What Kind Of Blog Is This ?"
                >
                  <validation-provider
                      #default="{ errors }"
                      rules="required"
                  >
                  <v-select
                      v-if="blogTypes"
                      v-model="blog.blogTypes"
                      :options="blogTypes"
                      :class="{'border-danger rounded':errors.length > 0 }"

                      :reduce="name => name.value"
                      label="name"
                  />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
              >
                <b-form-group
                    label="Summary"
                    label-for="summary"
                >
                  <validation-provider
                      #default="{ errors }"
                      rules="required"
                  >
                    <b-form-textarea
                        id="summary"
                        v-model="blog.shortDescription"
                        :state="errors.length > 0 ? false:null"
                        rows="2"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                    class="mb-2"
                    label="Content"
                    label-for="blog-content"
                >
                  <ckEditorMain ref="editorContent" :defaultContent="blog.fullDescription"
                                @getEditorContent="setEditorContent"
                  ></ckEditorMain>
                </b-form-group>
              </b-col>
              <b-col class="my-1" cols="12">
                <h4 class="mb-1">
                  Blog Picture
                </h4>
                <div class="">
                  <div
                      class=" position-relative border rounded shadow"
                      style="height: 10rem;width: 10rem"
                  >
                    <!--          <span-->
                    <!--              class="position-absolute cursor-pointer top-0 left-0 bg-white shadow d-flex align-items-center justify-content-center rounded-circle"-->
                    <!--              style="width: 2rem;height: 2rem"-->
                    <!--          >-->
                    <!--            <feather-icon class="text-danger" icon="TrashIcon"></feather-icon>-->
                    <!--          </span>-->
                    <img :src="blog.imageUrl" alt="" style="object-fit: contain;height: 10rem;width: 10rem">
                  </div>

                </div>

              </b-col>

              <b-col
                  class="mb-2"
                  cols="12"
              >
                <h4 class="mb-1">
                  New Picture
                </h4>
                <MediaHandler ref="mediaHandler" @sendData="setMainPicture"></MediaHandler>
              </b-col>
              <b-col
                  class="mt-50"
                  cols="12"
              >

              </b-col>
            </b-row>
          </b-form>

        </b-card>
        <!--/ form -->
        <b-card>
          <b-row>
            <b-col cols="12" md="12">
              <h2>SEO</h2>
            </b-col>
            <b-col class="" md="4">
              <b-form-group
                  label="Seo Title"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <b-form-input
                      id="data-edit-seoTitle"
                      v-model="blog.seoTitle"
                      :state="errors.length > 0 ? false:null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col class="" md="4">
              <b-form-group
                  label="Seo Url"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <b-form-input
                      id="data-edit-seoTitle"
                      v-model="allCharactersToDash"
                      :state="errors.length > 0 ? false:null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col class="" cols="12">
              <b-form-group
                  label="Seo Description"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >

                  <b-form-textarea
                      id="data-edit-seoTitle"
                      v-model="blog.seoDescription"
                      :state="errors.length > 0 ? false:null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" class="pl-1">
              <div class="col-md-12 ">
                <!--- serp Desktop --->
                <div class="serp-preview d-lg-flex d-none flex-column align-items-start">
                  <div class="serp-title">
                    <h4 style="color: rgb(26, 13, 171)!important">
                      {{ blog.seoTitle }}
                    </h4>
                  </div>
                  <div class="serp-url">
                    <h5 class="d-flex align-items-center flex-wrap" style="color: rgb(0, 102, 33)!important">
                      <span>https://skycoach.com/<span class="serp-arrow"></span></span>
                      <span style="overflow-wrap: anywhere">
                      {{ blog.seoUrl.replaceAll(' ', '-') }}
                    </span>

                    </h5>
                  </div>
                  <div class="serp-description">
                    <h6 style="overflow-wrap: anywhere">
                      {{ blog.seoDescription }}
                    </h6>
                  </div>
                </div>
                <!--- serp Mobile --->
                <div class="serp-preview d-lg-none  p-1 d-flex flex-column align-items-start">
                  <div class="serp-title">
                    <h6>
                      {{ blog.seoTitle }}
                    </h6>
                  </div>
                  <div class="serp-url d-flex align-items-center flex-wrap">

                    <small>{{ blog.seoUrl.replaceAll(' ', '-') }}</small>
                    <small>
                      https://skycoach.com/
                      <small class="serp-arrow"></small>
                    </small>
                  </div>
                  <div class="serp-description">
                    <small>
                      {{ blog.seoDescription }}
                    </small>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col cols="3">
              <b-button
                  class="btn-tour-skip mr-1"
                  variant="primary"
                  @click="validationForm"
              >
                <span class="mr-25 align-middle">Submit</span>

              </b-button>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-row>
            <b-col cols="12" md="12">
              <h2>Blog Contents</h2>
            </b-col>
            <b-col class="my-1" cols="3" md="1">
              <b-button
                  class="btn-tour-skip mr-1"
                  variant="primary"
              >
                <b-link
                    v-if="blog"
                    :to="`/pages/blog/blog-content/create-blog-content?blogId=${blog.blogId}`"
                >
                  <span class="mr-25 align-middle text-white">Create</span>
                </b-link>

              </b-button>
            </b-col>
            <!--              <b-col class="my-1" cols="3">-->
            <!--                <debouncer :placeHolder="`search by`" @setValue="getsearchValue"></debouncer>-->
            <!--              </b-col>-->
            <b-col cols="12">
              <b-table
                  ref="refUserListTable"
                  :fields="myTableColumns"
                  :items="blogContents"
                  :sort-by.sync="sortBy"
                  bordered
                  class="position-relative"
                  empty-text="Nothing found !"
                  primary-key="id"
                  responsive
                  show-empty
                  striped
              >

                <template #cell(imageUrl)="data">
                  <img :src="data.item.imageUrl" alt="" style="width: 5rem;height: 5rem;object-fit: contain">
                </template>
                <template #cell(operation)="data">
                  <div class="d-flex align-items-center  w-100">
                    <b-link
                        :to="`/pages/blog/blog-content/edit-blog-content?blogContentId=${data.item.blogContentId}`"
                    >
                      <feather-icon
                          class="text-primary"
                          icon="EditIcon"
                          size="20"
                      />
                    </b-link>
                    <span v-b-modal.modal-delete class="cursor-pointer mx-1" @click="setSelectedBlogContent(data.item)">
              <feather-icon
                  class="text-danger"
                  icon="TrashIcon"
                  size="20"
              />
              </span>
                  </div>
                </template>
              </b-table>

            </b-col>
            <b-col cols="12">
              <div class="mx-2 mb-2">
                <b-row>
                  <b-col
                      class="d-flex align-items-center justify-content-center"
                      cols="12"
                      sm="12"
                  >
                    <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                      cols="12"
                      sm="6"
                  >
                    <b-pagination
                        v-model="currentPage"
                        :per-page="perPage"
                        :total-rows="totalCount"
                        class="mb-0 mt-1 mt-sm-0"
                        first-number
                        last-number
                        next-class="next-item"
                        prev-class="prev-item"
                    >
                      <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-card>

      </validation-observer>

    </div>
  </b-overlay>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BImg,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BOverlay,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import {
  BlogCategoryGetAllRequest,
  BlogGetByIdRequest,
  BlogUpdateBlogRequest,
  DeleteBlogContent,
  GetBlogContentBy
} from '@/libs/Api/Blog'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ckEditorMain from '@/views/components/ckEditorMain.vue'
import { required } from '@validations'
import { GetAllGames } from '@/libs/Api/games'
import MediaHandler from '@/views/components/utilities/MediaHandler.vue'
import Helper from '@/libs/Helper'

export default {
  title: 'Blog Edeit',
  components: {
    MediaHandler,
    ckEditorMain,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BPagination,
    BTable,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormTextarea,
    BOverlay,
    vSelect,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      blog: null,
      blogId: this.$route.params.id,
      blogCategories: null,
      base64ImageSrc: null,
      required,
      blogEdit: {},
      games: null,
      blogFile: null,
      perPage: 10,
      currentPage: 1,
      totalCount: null,
      blogTypes: [
        {
          name: 'Guides',
          value: 'Guides'
        },
        {
          name: 'Class Guides',
          value: 'ClassGuides'
        },
        {
          name: 'General Guides',
          value: 'GeneralGuides'
        }
      ],
      selectedBlogContent: null,
      sortBy: '',
      blogContents: null,
      myTableColumns: [
        {
          key: 'blogContentId',
          label: 'id'
        },
        {
          key: 'title',
          label: 'title'
        },
        {
          key: 'operation',
          label: 'operation'
        }
      ],
      overlay: false,
    }
  },
  async created() {
    await Promise.all([

      this.getBlog(),
      this.getGames(),
      this.getBlogContents()
    ])
  },
  computed: {
    allCharactersToDash: {
      get: function () {
        return this.blog.seoUrl
      },
      set: function (newValue) {
        // This setter is getting number, replace all commas with empty str
        // Then start to separate numbers with ',' from beginning to prevent
        // from data corruption
        if (newValue) {
          this.blog.seoUrl = newValue
          // Remove all characters that are NOT number
          this.blog.seoUrl = this.blog.seoUrl.replace(/[+*!@#$%^&*()_;:~`»«,×='"|<>/?{}\-/\.]/g, '-')
          this.blog.seoUrl = this.blog.seoUrl.replaceAll(' ', '-')
        } else if (!newValue || this.blog.seoUrl === '') {
          this.blog.seoUrl = null
        }
      },
    },
  },
  methods: {
    async deleteBlogContent() {
      let _this = this
      _this.overlay = true

      let deleteBlogContent = new DeleteBlogContent(_this)
      deleteBlogContent.setParams({ blogContentId: _this.selectedBlogContent.blogContentId })
      await deleteBlogContent.sendFormData(function (content) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `Done`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `Blog edited.`,
          },
        })
        _this.getBlogContents()
      }, function (error) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
      })
    },
    setSelectedBlogContent(item) {
      this.selectedBlogContent = JSON.parse(JSON.stringify(item))
    },
    async getBlogContents() {
      let _this = this
      _this.showOverlay = true
      let getBlogContentBy = new GetBlogContentBy(_this)
      let data = {
        pageNumber: _this.currentPage,
        count: _this.perPage,
        blogId:_this.$route.params.id
      }
      getBlogContentBy.setParams(data)
      await getBlogContentBy.fetch(function (content) {
        _this.blogContents = content.data.data
        _this.totalCount = content.data.totalCount
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    async getGames() {
      let _this = this
      _this.showOverlay = true
      let getAllGames = new GetAllGames(_this)
      let data = {
        pageNumber: 1,
        count: 100,
        search: ''
      }
      getAllGames.setParams(data)
      await getAllGames.fetch(function (content) {
        _this.games = content.data.data
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },

    validationForm() {
      let _this = this
      Helper.validateForm(_this, 'refFormObserver', async () => {
        _this.updateBlog()
      })
    },
    async getBlog() {
      let _this = this
      _this.overlay = true

      let blogGetByIdRequest = new BlogGetByIdRequest(_this)
      blogGetByIdRequest.setParams({ blogId: _this.blogId })
      await blogGetByIdRequest.fetch(function (content) {
        _this.overlay = false
        _this.blog = content.data
      }, function () {
        _this.overlay = false
        console.log('Error')
      })
    },
    async getBlogCategories() {
      let _this = this
      _this.overlay = true

      let blogCategoryGetAllRequest = new BlogCategoryGetAllRequest(_this)
      await blogCategoryGetAllRequest.fetch(function (content) {
        _this.overlay = false
        _this.blogCategories = content
      }, function (content) {
        _this.overlay = false
        console.log(content)
      })
    },
    async updateBlog() {
      let _this = this
      _this.overlay = true

      let blogUpdateBlogRequest = new BlogUpdateBlogRequest(_this)
      _this.$refs.mediaHandler.sendFiles()
      let myForm = await Helper.makeFormData(_this.blog)
      blogUpdateBlogRequest.setParams(myForm)
      blogUpdateBlogRequest.setQuery({ blogId: _this.blog.blogId })
      await blogUpdateBlogRequest.sendFormData(function (content) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `Done`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `Blog edited.`,
          },
        })
        _this.getBlog()
        _this.$refs.mediaHandler.clearMedia()
      }, function (error) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
      })
    },
    async readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader()
        fr.onerror = reject
        fr.onload = () => {
          resolve(fr.result.split(',')[1])
        }
        fr.readAsDataURL(file)
      })
    },
    async makeBase64Pic() {
      const _this = this
      let file = _this.$refs.refInputEl.files[0]
      _this.base64ImageSrc = URL.createObjectURL(file)
      const result = await _this.readAsDataURL(file)
      _this.blog.fileData = result
    },
    setEditorContent(content) {
      this.blog.fullDescription = content
    },
    setMainPicture(data) {
      this.blog.ImageFile = data
    },
    createJalaliDate(param) {
      if (param !== '' && param !== null) {
        return param.slice(11, 16) + ' - ' + new Date(param.slice(0, 10)).toLocaleDateString('fa-IR',)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
